import {
  Limit,
  ServicesGroup,
  useLimits,
} from "@/pages/settings/react-query/limit-management";
import { Button, Select } from "@hyperlocal/vital2";
import * as Accordion from "@radix-ui/react-accordion";
import { useLimitEditor } from "../use-limit-management";
import { getServiceGroupDisplayName } from "../utils";
import { CurrencyInput } from "./currency-input";

interface GroupedLimit {
  daytimeTotal?: Limit;
  nighttimeTotal: Limit;
}

interface PaymentLimitItemProps {
  group: string;
  data: GroupedLimit;
  allServiceGroups: ServicesGroup[];
  existingGroups: ServicesGroup[];
  onRemoveGroup: (group: ServicesGroup) => void;
  onChangeServiceGroup: (
    current: ServicesGroup,
    newGroup: ServicesGroup,
  ) => void;
  onCurrencyChange: (
    group: string,
    type: "day" | "night",
    value: number,
  ) => void;
}

export function PaymentLimitItem({
  group,
  data,
  allServiceGroups,
  existingGroups,
  onRemoveGroup,
  onChangeServiceGroup,
  onCurrencyChange,
}: PaymentLimitItemProps) {
  return (
    <Accordion.Item
      key={group}
      value={group}
      className="mb-4 rounded-md border"
    >
      <div className="flex items-center justify-between px-4">
        <Accordion.Trigger className="py-2">
          <div className="flex items-center space-x-2">
            <h3 className="text-base font-medium">
              {getServiceGroupDisplayName(group as ServicesGroup)}
            </h3>
          </div>
        </Accordion.Trigger>
        <Button.Root
          variant="secondary"
          className="border-none"
          onClick={() => onRemoveGroup(group as ServicesGroup)}
        >
          <Button.Slot name="SettingTrashTrash" className="h-6 w-6">
            <span className="sr-only">Remover</span>
          </Button.Slot>
        </Button.Root>
      </div>
      <Accordion.Content className="px-4 pb-4">
        <div className="space-y-4">
          <div className="w-full">
            <label className="mb-1 block text-xs font-medium">
              Grupo de Serviço
            </label>
            <Select.Root
              value={group}
              onValueChange={(newValue) =>
                onChangeServiceGroup(
                  group as ServicesGroup,
                  newValue as ServicesGroup,
                )
              }
            >
              <Select.Trigger className="w-full">
                <Select.Value placeholder="Selecione">
                  {getServiceGroupDisplayName(group as ServicesGroup)}{" "}
                </Select.Value>
              </Select.Trigger>
              <Select.Content>
                {allServiceGroups?.map((option) => (
                  <Select.Item
                    key={option}
                    value={option}
                    disabled={
                      existingGroups.includes(option) && option !== group
                    }
                  >
                    {getServiceGroupDisplayName(option)}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="mb-1 block text-xs font-medium">Noturno</label>
              <CurrencyInput
                value={data.nighttimeTotal?.availableLimit || 0}
                onChange={(value) => onCurrencyChange(group, "night", value)}
                className="w-full"
              />
            </div>
            <div>
              <label className="mb-1 block text-xs font-medium">Diurno</label>
              <CurrencyInput
                value={data.daytimeTotal?.availableLimit || 0}
                onChange={(value) => onCurrencyChange(group, "day", value)}
                className="w-full"
              />
            </div>
          </div>
        </div>
      </Accordion.Content>
    </Accordion.Item>
  );
}

export function PaymentLimitsAccordion() {
  const { data } = useLimits();

  const {
    openItems,
    groupedLimits,
    availableGroups,
    existingGroups,
    addNewServiceGroup,
    handleChangeServiceGroup,
    handleRemoveGroup,
    handleAccordionChange,
    handleCurrencyChange,
  } = useLimitEditor(data?.limits || []);

  const hasAvailableGroups = availableGroups.length > 0;

  const handleAddNewLimit = () => {
    if (hasAvailableGroups) {
      addNewServiceGroup(availableGroups[0]);
    }
  };

  return (
    <div className="mx-auto w-full max-w-md">
      <Accordion.Root
        type="multiple"
        className="w-full"
        value={openItems}
        onValueChange={handleAccordionChange}
      >
        {Object.entries(groupedLimits).map(([group, data]) => (
          <PaymentLimitItem
            key={group}
            group={group}
            data={data}
            allServiceGroups={availableGroups}
            existingGroups={existingGroups}
            onRemoveGroup={handleRemoveGroup}
            onChangeServiceGroup={handleChangeServiceGroup}
            onCurrencyChange={handleCurrencyChange}
          />
        ))}
      </Accordion.Root>
      <div className="mt-4">
        <Button.Root
          onClick={handleAddNewLimit}
          variant="link"
          disabled={!hasAvailableGroups}
        >
          {hasAvailableGroups
            ? "Ajustar um limite"
            : "Todos os limites preenchidos"}
        </Button.Root>
      </div>
    </div>
  );
}
