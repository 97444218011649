import { MFASheet } from "@/components/mfa-sheet";
import {
  useLimits,
  useRequestLimits,
} from "../../react-query/limit-management";
import {
  useLimitStoreActions,
  useLimitStoreState,
} from "../../store/limit-management";
import { LimitsTable } from "./components/limits-table";

type SkeletonLimitsTableProps = {
  title: string;
  rowCount?: number;
};

export function SkeletonLimitsTable({
  title,
  rowCount = 3,
}: SkeletonLimitsTableProps) {
  return (
    <table className="w-full border-collapse rounded-md shadow-md">
      <thead className="rounded-lg">
        <tr className="rounded-lg bg-primary-lighter text-left font-bold text-primary-main">
          <th className="p-3 text-sm" colSpan={3}>
            {title}
          </th>
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: rowCount }).map((_, index) => (
          <tr
            key={`skeleton-row-${index}`}
            className="border-t text-xs text-neutral-darkest"
          >
            <td className="p-3 font-medium">
              <div className="h-4 w-32 animate-pulse rounded bg-neutral-light"></div>
            </td>
            <td className="p-3">
              <div className="h-4 w-20 animate-pulse rounded bg-neutral-light"></div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function LimitManagement() {
  const { isLoading, error, formattedLimits } = useLimits();
  const { MFASheet: isMFASheetOpen, requestPayload } = useLimitStoreState();
  const { toggleMFASheet } = useLimitStoreActions();

  const limitMutation = useRequestLimits();

  const handleRequestLimits = () => {
    limitMutation.mutate(requestPayload);
  };

  if (isLoading) {
    return (
      <div className="flex h-full w-full flex-col gap-4 overflow-hidden">
        <SkeletonLimitsTable title="Limites Diurnos" rowCount={4} />
        <SkeletonLimitsTable title="Limites Noturnos" rowCount={4} />
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex h-64 items-center justify-center">
        Erro ao carregar limites. Tente novamente mais tarde.
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col gap-4 overflow-hidden">
      <LimitsTable
        title="Limites Diurnos"
        items={formattedLimits.daytimeLimits}
      />
      <LimitsTable
        title="Limites Noturnos"
        items={formattedLimits.nighttimeLimits}
      />
      <MFASheet
        onSubmitToken={handleRequestLimits}
        isOpen={isMFASheetOpen === "open"}
        onClose={toggleMFASheet}
        isLoading={isLoading}
      />
    </div>
  );
}
