import {
  useLimitStoreActions,
  useLimitStoreState,
} from "@/pages/settings/store/limit-management";
import { Button, Sheet } from "@hyperlocal/vital2";
import { PaymentLimitsAccordion } from "./payment-limits-accordion";

export function RequestLimitsSheet() {
  const { modifiedLimits } = useLimitStoreState();
  const { toggleMFASheet } = useLimitStoreActions();

  const isRequestButtonDisabled = modifiedLimits.length === 0;

  const handleRequestLimits = () => {
    if (modifiedLimits.length > 0) {
      toggleMFASheet();
    }
  };

  return (
    <Sheet.Root>
      <Sheet.Trigger asChild>
        <Button.Root type="button">Solicitar ajuste de limites</Button.Root>
      </Sheet.Trigger>
      <Sheet.Content>
        <Sheet.Header>
          <Sheet.Title>Ajuste de Limite</Sheet.Title>
        </Sheet.Header>
        <PaymentLimitsAccordion />
        <Sheet.Footer className="mt-auto flex w-full !flex-col gap-4">
          <span className="text-xs text-neutral-darkest">
            Solicitação para valores totais
          </span>
          <Sheet.Close asChild>
            <Button.Root
              variant="primary"
              className="w-full"
              disabled={isRequestButtonDisabled}
              onClick={handleRequestLimits}
            >
              Solicitar
            </Button.Root>
          </Sheet.Close>
        </Sheet.Footer>
      </Sheet.Content>
    </Sheet.Root>
  );
}
