import { useCallback, useEffect, useState } from "react";
import {
  BeneficiaryType,
  GroupedLimit,
  Limit,
  LimitType,
  ServicesGroup,
} from "../../react-query/limit-management";
import { useLimitStoreActions } from "../../store/limit-management";

export function useLimitEditor(initialLimits: Limit[] = []) {
  const [limits, setLimits] = useState<Limit[]>([initialLimits[0]]);
  const [openItems, setOpenItems] = useState<string[]>([]);

  const { updateModifiedLimits, updateRequestPayload } = useLimitStoreActions();

  const relevantLimits = limits.filter(
    (limit) =>
      limit.limitType === "DaytimeTotalLimit" ||
      limit.limitType === "NighttimeTotalLimit",
  );

  const groupedLimits = relevantLimits.reduce<Record<string, GroupedLimit>>(
    (acc, limit) => {
      const group = limit.servicesGroup;

      if (!acc[group]) {
        acc[group] = { nighttimeTotal: {} as Limit };
      }

      if (limit.limitType === "DaytimeTotalLimit") {
        acc[group].daytimeTotal = limit;
      } else if (limit.limitType === "NighttimeTotalLimit") {
        acc[group].nighttimeTotal = limit;
      }

      return acc;
    },
    {},
  );

  const allServiceGroups: ServicesGroup[] = [
    "BillPayments",
    "PixWithdrawOrChange",
    "TED",
    "Pix",
    "P2P",
  ];

  const existingGroups = Object.keys(groupedLimits) as ServicesGroup[];

  const availableGroups = allServiceGroups.filter(
    (group) => !existingGroups.includes(group),
  );
  const getModifiedLimits = useCallback((): Limit[] => {
    return limits.filter((currentLimit) => {
      const originalLimit = initialLimits.find(
        (origLimit) =>
          origLimit.servicesGroup === currentLimit.servicesGroup &&
          origLimit.limitType === currentLimit.limitType,
      );

      if (!originalLimit) return true;

      return currentLimit.availableLimit !== originalLimit.availableLimit;
    });
  }, [initialLimits, limits]);

  useEffect(() => {
    if (existingGroups.length > 0 && openItems.length === 0) {
      setOpenItems([existingGroups[0]]);
    }
  }, [existingGroups, openItems]);

  const formatRequestPayload = useCallback((modifiedLimits: Limit[]) => {
    const serviceGroups = [
      ...new Set(modifiedLimits.map((limit) => limit.servicesGroup)),
    ];

    const formattedLimits = serviceGroups.map((serviceGroup) => {
      const limitsForGroup = modifiedLimits.reduce(
        (acc, limit) => {
          if (limit.servicesGroup === serviceGroup) {
            if (limit.limitType === "DaytimeTotalLimit") {
              acc.daytimeTotalLimit = limit.availableLimit;
            } else if (limit.limitType === "NighttimeTotalLimit") {
              acc.nighttimeTotalLimit = limit.availableLimit;
            }
          }
          return acc;
        },
        {
          daytimeTotalLimit: 0,
          nighttimeTotalLimit: 0,
        },
      );

      return {
        serviceGroup,
        ...limitsForGroup,
      };
    });

    return {
      limits: formattedLimits,
    };
  }, []);

  const handleLimitsUpdate = useCallback(() => {
    const modifiedLimits = getModifiedLimits();
    updateModifiedLimits(modifiedLimits);

    const payload = formatRequestPayload(modifiedLimits);
    updateRequestPayload(payload);
  }, [
    getModifiedLimits,
    updateModifiedLimits,
    updateRequestPayload,
    formatRequestPayload,
  ]);

  useEffect(() => {
    handleLimitsUpdate();
  }, [handleLimitsUpdate]);

  const addNewServiceGroup = (
    newGroup: ServicesGroup,
    beneficiaryType: BeneficiaryType = "Person",
  ) => {
    const newLimits: Limit[] = [
      ...limits,
      {
        limitType: "DaytimeTotalLimit",
        defaultLimit: 0,
        accountLimit: 0,
        servicesGroup: newGroup,
        availableLimit: 0,
        beneficiaryType,
      },
      {
        limitType: "NighttimeTotalLimit",
        defaultLimit: 0,
        accountLimit: 0,
        servicesGroup: newGroup,
        availableLimit: 0,
        beneficiaryType,
      },
    ];

    setLimits(newLimits);
    setOpenItems([newGroup]);
  };

  const handleChangeServiceGroup = (
    currentGroup: ServicesGroup,
    newGroup: ServicesGroup,
  ) => {
    const updatedLimits = limits.map((limit) =>
      limit.servicesGroup === currentGroup
        ? { ...limit, servicesGroup: newGroup }
        : limit,
    );

    setLimits(updatedLimits);

    setOpenItems((prev) => {
      const newItems = [...prev];
      const index = newItems.indexOf(currentGroup);
      if (index !== -1) {
        newItems[index] = newGroup;
      }
      return newItems;
    });
  };

  const handleChange = (
    servicesGroup: ServicesGroup,
    limitType: LimitType,
    newValue: number,
  ) => {
    const updatedLimits = limits.map((limit) =>
      limit.servicesGroup === servicesGroup && limit.limitType === limitType
        ? { ...limit, availableLimit: newValue }
        : limit,
    );

    setLimits(updatedLimits);
  };

  const handleRemoveGroup = (servicesGroup: ServicesGroup) => {
    const updatedLimits = limits.filter(
      (limit) => limit.servicesGroup !== servicesGroup,
    );
    setLimits(updatedLimits);

    setOpenItems((prev) => prev.filter((item) => item !== servicesGroup));
  };

  const handleAccordionChange = (value: string[]) => {
    setOpenItems(value);
  };

  const handleCurrencyChange = (
    group: string,
    type: "day" | "night",
    value: number,
  ) => {
    const limitExists = limits.some(
      (limit) =>
        limit.servicesGroup === group &&
        limit.limitType ===
          (type === "day" ? "DaytimeTotalLimit" : "NighttimeTotalLimit"),
    );

    if (!limitExists) {
      console.warn(`Limite não encontrado para ${group} - ${type}`);
      return;
    }

    handleChange(
      group as ServicesGroup,
      type === "day" ? "DaytimeTotalLimit" : "NighttimeTotalLimit",
      value,
    );
  };

  return {
    limits,
    setLimits,
    openItems,
    groupedLimits,
    availableGroups,
    existingGroups,
    addNewServiceGroup,
    handleChangeServiceGroup,
    handleChange,
    handleRemoveGroup,
    getModifiedLimits,
    formatRequestPayload,
    handleAccordionChange,
    handleCurrencyChange,
  };
}
