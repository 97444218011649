import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";
import {
  Limit,
  RequestLimitsPayload,
} from "../../react-query/limit-management";

type State = {
  MFASheet: "open" | "closed";
  modifiedLimits: Limit[];
  requestPayload: Omit<RequestLimitsPayload, "accountId">;
};

type Actions = {
  toggleMFASheet: () => void;
  updateModifiedLimits: (limits: Limit[]) => void;
  updateRequestPayload: (
    payload: Omit<RequestLimitsPayload, "accountId">,
  ) => void;
};

type LimitStore = State & { actions: Actions };

const limitStore = create<LimitStore>((set) => ({
  MFASheet: "closed",
  modifiedLimits: [],
  requestPayload: { accountId: "", limits: [] },
  actions: {
    toggleMFASheet: () =>
      set((state) => ({
        MFASheet: state.MFASheet === "open" ? "closed" : "open",
      })),
    updateModifiedLimits: (limits) => set({ modifiedLimits: limits }),
    updateRequestPayload: (payload) => set({ requestPayload: payload }),
  },
}));

export const useLimitStoreState = () =>
  limitStore(
    useShallow((state) => ({
      MFASheet: state.MFASheet,
      modifiedLimits: state.modifiedLimits,
      requestPayload: state.requestPayload,
    })),
  );

export const useLimitStoreActions = () => limitStore((state) => state.actions);
