import { useState } from "react";
import { useRequestTaxIncome } from "@/api/tax-income/use-request-tax-income";
import { hasPermission, useAccountStore } from "@/utils";
import { seeEquipAccess } from "@/utils/allowed-roles";
import { Button, Select } from "@hyperlocal/vital2";

export const IncomeTax = () => {
  const { account } = useAccountStore();

  const isPj = !!account?.companyInfoResponse?.name;
  // const { isError, isLoading, isFetching } = useListTaxReports();
  const [reportCode, setReportCode] = useState("");

  const requestTaxIncomeMutation = useRequestTaxIncome();

  const onRequest = async () => {
    await requestTaxIncomeMutation.mutateAsync({ reportCode: "2024-Q4" });
  };

  const shouldBeAbletoRequest = hasPermission(seeEquipAccess) && isPj;

  return (
    <div className="flex h-full flex-col items-end justify-between mobile:items-start">
      <div>
        <h5 className="mb-4 text-sm/md font-bold text-neutral-darkest mobile:!text-xs/md">
          Relatório de IR
        </h5>
        <span>
          Selecione o período desejado e o arquivo será enviado para o seu
          e-mail de acesso a conta digital.
        </span>
        <div className="mt-6 w-full">
          <Select.Root value={reportCode} onValueChange={setReportCode}>
            <Select.Label>Período</Select.Label>
            <Select.Trigger
              className="w-full max-w-[50%] hover:cursor-pointer disabled:cursor-not-allowed mobile:max-w-[374px]"
              disabled
            >
              <Select.Value placeholder="2024-Q4" />
            </Select.Trigger>
            <Select.Content></Select.Content>
          </Select.Root>
        </div>
      </div>
      <Button.Root
        onClick={onRequest}
        isLoading={requestTaxIncomeMutation.isPending}
        className="mobile:w-full"
        disabled={!shouldBeAbletoRequest}
      >
        Solicitar
      </Button.Root>
    </div>
  );
};
