import { FormattedLimit } from "@/pages/settings/react-query/limit-management";
import { getServiceGroupDisplayName } from "../utils";

export type LimitTableProps = {
  title: string;
  items: FormattedLimit[];
};

export function LimitsTable({ title, items }: LimitTableProps) {
  return (
    <>
      <table className="w-full border-collapse rounded-md shadow-md">
        <thead className="rounded-lg">
          <tr className="rounded-lg bg-primary-lighter text-left font-bold text-primary-main">
            <th className="p-3 text-sm" colSpan={3}>
              {title}
            </th>
          </tr>
        </thead>
        <tbody>
          {items?.length > 0 ? (
            items.map((item, index) => (
              <tr
                key={`${item.type}-${index}`}
                className="border-t text-xs text-neutral-darkest"
              >
                <td className="p-3 font-medium">
                  {getServiceGroupDisplayName(item.type)}
                </td>
                <td className="p-3">{item.limit}</td>
              </tr>
            ))
          ) : (
            <tr className="border-t text-xs text-neutral-darkest">
              <td colSpan={3} className="p-3 text-center">
                Nenhum limite encontrado
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}
