import { TabParam } from "@/pages/settings";
import {
  AccessGroupName,
  hasPermission,
  Hooks,
  openExternalLink,
  useAccountStore,
} from "@/utils";
import { seeAccountData, seeEquipAccess } from "@/utils/allowed-roles";
import { Button } from "@hyperlocal/vital2";
import { twMerge } from "tailwind-merge";
import * as Svg from "../svgs";
import { Devices } from "../svgs";

type NavigationProps = {
  activeParam: TabParam;
  handleChangeTabParam: (tab: TabParam) => void;
};

type TabOption = {
  text: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  param: TabParam;
  allowedRoles: AccessGroupName[];
  hide?: boolean;
};

export function Navigation({
  activeParam,
  handleChangeTabParam,
}: Readonly<NavigationProps>) {
  const isMobile = Hooks.useMediaQuery("mobile");

  const { account } = useAccountStore();

  const isPj = !!account?.companyInfoResponse?.name;

  const TABS_OPTIONS: TabOption[] = [
    {
      text: "Dados da conta",
      icon: Svg.Person,
      param: "dados-da-conta",
      allowedRoles: seeAccountData,
    },
    {
      text: "Acesso da equipe",
      icon: Svg.Persons,
      param: "acesso-da-equipe",
      allowedRoles: seeEquipAccess,
    },
    {
      text: "Gestão de limites",
      icon: Svg.DisplaySettings,
      param: "gestao-de-limites",
      allowedRoles: seeAccountData,
    },
    {
      text: "Tarifas",
      icon: Svg.RequestPage,
      param: "tarifas",
      allowedRoles: seeAccountData,
    },
    // { text: "Notificações", icon: Svg.Notifications, param: "notificacoes" },
    {
      text: "Favorecidos",
      icon: Svg.Star,
      param: "favorecidos",
      allowedRoles: seeEquipAccess,
    },
    {
      text: "Dispositivos Confiáveis",
      icon: Devices,
      param: "dispositivos-confiaveis",
      allowedRoles: seeAccountData,
    },
    {
      text: "Relatório de IR",
      icon: Svg.Ballot,
      param: "relatorio-IR",
      allowedRoles: seeEquipAccess,
      hide: !isPj,
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      {TABS_OPTIONS.map((option) => (
        <Button.Root
          key={option.text}
          onClick={() => handleChangeTabParam(option.param)}
          className={twMerge(
            "justify-start border-none px-4 font-bold",
            activeParam !== option.param &&
              "bg-transparent text-neutral-darkest hover:text-neutral-white focus:text-neutral-white",
            (!hasPermission(option.allowedRoles) || option.hide) && "hidden",
          )}
        >
          <option.icon /> {option.text}
          {isMobile && <Svg.ArrowDown className="ml-auto rotate-180" />}
        </Button.Root>
      ))}
      <Button.Root
        onClick={() =>
          openExternalLink(
            "https://sites.google.com/hyperlocal.com.br/banking/",
          )
        }
        className={twMerge(
          "justify-start border-none px-4 font-bold",
          "bg-transparent text-neutral-darkest hover:text-neutral-white focus:text-neutral-white",
        )}
      >
        <Svg.Faq /> FAQ
        {isMobile && <Svg.ArrowDown className="ml-auto rotate-180" />}
      </Button.Root>

      {/* <Button.Root className="justify-start border-none bg-transparent px-4 font-bold text-status-error-dark hover:bg-status-error-dark hover:text-neutral-white">
        <Svg.CloseRounded /> Cancelamento de conta
      </Button.Root> */}
    </div>
  );
}
