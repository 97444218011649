import { useEffect, useRef, useState } from "react";
import { TextField } from "@hyperlocal/vital2";
import {
  formatBrazilianReal,
  parseBrlToDisplay,
  parseBRLToNumber,
} from "../utils";

export function CurrencyInput({
  value,
  onChange,
  className = "",
  placeholder = "R$ 0,00",
  disabled = false,
  ...props
}) {
  const isInternalChange = useRef(false);

  const [displayValue, setDisplayValue] = useState<string>("");

  useEffect(() => {
    if (!isInternalChange.current) {
      setDisplayValue(parseBrlToDisplay(value || 0));
    }
    isInternalChange.current = false;
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericString = inputValue.replace(/\D/g, "");

    if (!numericString) {
      setDisplayValue("");
      onChange(0);
      return;
    }

    const numericValue = parseInt(numericString, 10);
    const valueToDisplay = formatBrazilianReal(numericValue);
    const valueToApi = parseBRLToNumber(valueToDisplay);

    setDisplayValue(valueToDisplay);

    onChange(valueToApi);
  };

  return (
    <TextField.Input
      type="text"
      value={displayValue}
      onChange={handleChange}
      className={className}
      placeholder={placeholder}
      disabled={disabled}
      onFocus={(e) => {
        e.target.select();
      }}
      {...props}
    />
  );
}
