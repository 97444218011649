import { ServicesGroup } from "../../react-query/limit-management";

export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const extractNumberFromCurrency = (formattedValue: string): number => {
  const cleaned = formattedValue.replace(/[^\d,.-]/g, "");
  const standardized = cleaned.replace(/\./g, "").replace(",", ".");
  return parseFloat(standardized) || 0;
};

export const getServiceGroupDisplayName = (
  servicesGroup: ServicesGroup,
): string => {
  const displayNames: Record<ServicesGroup, string> = {
    BillPayments: "Pagamento de Boleto",
    PixWithdrawOrChange: "Pix Saque ou Troco",
    TED: "TED",
    Pix: "PIX",
    P2P: "P2P",
  };

  return displayNames[servicesGroup] || servicesGroup;
};

export function parseBRLToNumber(value: string): number {
  return parseFloat(
    value
      .replace(/\./g, "")
      .replace(",", ".")
      .replace(/[^\d.]/g, ""),
  ).toFixed(2) as unknown as number;
}

export function formatBrazilianReal(value: string | number): string {
  const numberValue = typeof value === "string" ? parseFloat(value) : value;

  if (isNaN(numberValue)) {
    throw new Error(
      "Valor inválido. Certifique-se de passar um número ou uma string numérica.",
    );
  }

  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(numberValue / 100);
}

export function parseBrlToDisplay(value: number): string {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}
