import { API, useAccountStore } from "@/utils/utility";
import { toast } from "@hyperlocal/vital2";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

export const beneficiaryTypes = {
  Person: "Person",
  Company: "Company",
} as const;

export type BeneficiaryType = keyof typeof beneficiaryTypes;

export type LimitType =
  | "DaytimeTransactionalLimit"
  | "DaytimeTotalLimit"
  | "MonthlyLimit"
  | "NighttimeTotalLimit"
  | "NighttimeTransactionalLimit";

export type ServicesGroup =
  | "BillPayments"
  | "PixWithdrawOrChange"
  | "TED"
  | "Pix"
  | "P2P";

export type Limit = {
  limitType: LimitType;
  defaultLimit: number;
  accountLimit: number;
  servicesGroup: ServicesGroup;
  availableLimit: number;
  beneficiaryType: BeneficiaryType;
};

type ApiResponse = {
  accountId: string;
  limits: Limit[];
};

export type FormattedLimit = {
  type: ServicesGroup;
  limit: string;
};

export type ProcessedLimits = {
  daytimeLimits: FormattedLimit[];
  nighttimeLimits: FormattedLimit[];
};

export type GroupedLimit = {
  daytimeTotal?: Limit;
  nighttimeTotal: Limit;
};

export type RequestLimitsPayload = {
  accountId: string;
  limits: {
    serviceGroup: ServicesGroup;
    daytimeTotalLimit: number;
    nighttimeTotalLimit: number;
  }[];
};

export interface FormattedLimits {
  daytimeLimits: FormattedLimit[];
  nighttimeLimits: FormattedLimit[];
}

const FIVE_MINUTES = 5000 * 60;

const requestLimits = async (
  payload: RequestLimitsPayload,
): Promise<Limit[]> => {
  const response = await API.accessControl.post<ApiResponse>(
    "/api/Account/Limits/Request?api-version=1.0",
    payload,
  );
  return response.data.limits;
};

const listLimits = async (): Promise<ApiResponse> => {
  const response = await API.accessControl.get<ApiResponse>(
    "/api/Account/Limits",
  );
  return response.data;
};

const limitsQueryFactory = {
  default: ["limits"] as const,
  list: (beneficiaryType: BeneficiaryType) =>
    queryOptions({
      queryKey: [...limitsQueryFactory.default, beneficiaryType],
      queryFn: () => listLimits(),
      staleTime: FIVE_MINUTES,
    }),
};

export function useLimits() {
  const { account } = useAccountStore();
  const beneficiaryType = account.personInfoResponse
    ? beneficiaryTypes.Person
    : beneficiaryTypes.Company;

  const query = useQuery(limitsQueryFactory.list(beneficiaryType));

  return {
    ...query,
    formattedLimits: formattedLimitsForUI(query.data, beneficiaryType),
  };
}

export function useRequestLimits() {
  const { currentAccountId } = useAccountStore();
  return useMutation({
    mutationFn: ({ limits }: Omit<RequestLimitsPayload, "accountId">) =>
      requestLimits({
        accountId: currentAccountId,
        limits,
      }),
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Ajuste de limite solicitado, o retorno será enviado por email",
      });
    },
    onError: () => {
      toast({
        variant: "error",
        title: "Erro ao solicitar ajuste de limite",
      });
    },
  });
}

const formattedLimitsForUI = (
  data: ApiResponse,
  beneficiaryType: BeneficiaryType,
): ProcessedLimits => {
  const formatLimit = (limit: Limit): FormattedLimit => ({
    type: limit.servicesGroup,
    limit: `R$ ${limit.availableLimit.toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`,
  });

  const filtered = data?.limits.reduce<{
    daytimeLimits: FormattedLimit[];
    nighttimeLimits: FormattedLimit[];
  }>(
    (result, limit) => {
      if (limit.beneficiaryType === beneficiaryType) {
        const formattedLimit = formatLimit(limit);
        if (limit.limitType === "DaytimeTotalLimit") {
          result.daytimeLimits.push(formattedLimit);
        } else if (limit.limitType === "NighttimeTotalLimit") {
          result.nighttimeLimits.push(formattedLimit);
        }
      }
      return result;
    },
    {
      daytimeLimits: [],
      nighttimeLimits: [],
    },
  );

  return {
    daytimeLimits: filtered?.daytimeLimits,
    nighttimeLimits: filtered?.nighttimeLimits,
  };
};
