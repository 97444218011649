import { useEffect } from "react";
import {
  useRegisterDevice,
  useVerifyDeviceQuery,
} from "@/api/device-fingerprint";
import {
  hasPermission,
  useAccountStore,
  useDeviceFingerprint,
  useTrustedDeviceStore,
} from "@/utils/utility";

export function useDeviceRegistrationFlow() {
  const { step, deviceAlias, error, setStep, setDeviceAlias, setError, reset } =
    useTrustedDeviceStore();
  const { currentAccountId } = useAccountStore();
  const canRegisterDevice = hasPermission(["Administrador", "Proprietário"]);
  const deviceFingerPrint = useDeviceFingerprint();
  const deviceId = deviceFingerPrint?.deviceId;
  const { isLoading, isError } = useVerifyDeviceQuery({
    enableQuery: canRegisterDevice && !!deviceId,
    deviceId,
  });

  const registerDeviceMutation = useRegisterDevice();

  useEffect(() => {
    if (!isLoading && isError) {
      setStep("register");
    }
  }, [isLoading, isError, setStep]);

  const handleRegistration = (accepted: boolean) => {
    setStep(accepted ? "set_alias" : "confirm_deny");
  };

  const handleConfirmDeny = (confirmed: boolean) => {
    if (confirmed) {
      reset();
    } else {
      setStep("set_alias");
    }
  };

  const handleSetAlias = (alias: string) => {
    if (!alias) {
      setError("O nome do dispositivo não pode estar vazio!");
      return;
    }
    setDeviceAlias(alias);
    setError(null);
    setStep("set_mfa");
  };

  const handleMfaSubmit = (token: string) => {
    registerDeviceMutation.mutate({
      deviceId,
      latitude: deviceFingerPrint?.latitude,
      longitude: deviceFingerPrint?.longitude,
      accountId: currentAccountId,
      ipAddress: deviceFingerPrint?.ipAddress,
      deviceAlias,
      deviceModel: deviceFingerPrint?.deviceModel,
      operatingSystem: deviceFingerPrint?.operatingSystem,
      token,
    });
  };

  return {
    isLoading,
    step,
    deviceAlias,
    error,
    isRegistering: registerDeviceMutation.isPending,
    handleRegistration,
    handleConfirmDeny,
    handleSetAlias,
    handleMfaSubmit,
    reset,
  };
}
